import {
  ExperienceIcon,
  ConnectIcon,
  DiscoverIcon,
  NoContentIcon,
} from "../Icons";
import { Container, Content } from "./HeroElements";

const Hero = ({ content, slug }: HeroProps) => {
  let Icon = null;
  switch (slug) {
    case "discover":
      Icon = DiscoverIcon;
      break;
    case "connect":
      Icon = ConnectIcon;
      break;
    case "experience":
      Icon = ExperienceIcon;
      break;
    default:
      Icon = NoContentIcon;
      break;
  }
  return (
    <Container img={content.picture.src}>
      <Content>
        <Icon />
        <h1>{content.title}</h1>
      </Content>
    </Container>
  );
};

export default Hero;
