import { NoContentIcon } from "../Icons";
import { Container, ChapterInfo } from "./UnavailableContentElement";

const UnavailableContent = ({ noContent }: NoContentProps) => {
  return (
    <Container>
      <NoContentIcon />
      <ChapterInfo>
        <h2>{noContent.firstInfo.title}</h2>
        <p>{noContent.firstInfo.content}</p>
      </ChapterInfo>
      <ChapterInfo>
        <h2>{noContent.secondInfo.title}</h2>
        <p>{noContent.secondInfo.content}</p>
      </ChapterInfo>
    </Container>
  );
};

export default UnavailableContent;
