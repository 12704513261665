import { createGlobalStyle } from "styled-components";
import { reset } from "styled-reset";
import NouvelRRegularWoff2 from "./fonts/NouvelR-Regular.woff2";
import NouvelRRegularWoff from "./fonts/NouvelR-Regular.woff";
import NouvelRBoldWoff2 from "./fonts/NouvelR-Bold.woff2";
import NouvelRBoldWoff from "./fonts/NouvelR-Bold.woff";

// import reportWebVitals from './reportWebVitals';
export const GlobalStyle = createGlobalStyle`
  *, *:after,*:before {
    box-sizing: border-box;
  }

  @font-face {
    font-family: 'NouvelR';
    font-display: swap;
    font-weight: 400;
    src: url(${NouvelRRegularWoff2}) format('woff2'),
      url(${NouvelRRegularWoff}) format('woff');
    unicode-range: u+000-20cf;
  }

  @font-face {
    font-family: 'NouvelR';
    font-display: swap;
    font-weight: 700;
    src: url(${NouvelRBoldWoff2}) format('woff2'),
      url(${NouvelRBoldWoff}) format('woff');
    unicode-range: u+000-20cf;
  }

  ${reset}

  html,
  body {
    margin: 0;
    font-size: 62.5%;
    font-size: calc(0.4em + 1vmin);
    font-family: 'NouvelR', sans-serif !important;
    height: 100vh;
  }

  #modal-root + #root {
    overflow: hidden;
    position: fixed;
  }
  
  #modal-root {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100vw;
    height: 100vh;
  }
`;
