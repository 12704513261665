import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  overflow: hidden;
`;

export const Step = styled(Link)<{ $active: boolean }>`
  padding: 1rem;
  border: 2px solid ${({ $active }) => ($active ? "#efdf00" : "#bbbcbc")};
  position: relative;
  background-color: #ffffff;

  svg {
    fill: #000;
    width: 1.6rem;
    height: 1.6rem;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    right: -2.5rem;
    height: 2rem;
    width: 50vw;
    background-color: ${({ $active }) => ($active ? "#efdf00" : "#bbbcbc")};
  }
`;
