import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { SwipeEventData, useSwipeable } from "react-swipeable";
import { usePages } from "../context/PagesContext";

const useNavigation = () => {
  const { pages, currentPageIndex } = usePages();
  const navigate = useNavigate();

  const handleSwap = useCallback(
    (dir: number) => (e: SwipeEventData) => {
      if (
        (e.event.target as HTMLElement)?.matches(
          ".avoid-swipping, .avoid-swipping *"
        )
      ) {
        return;
      }

      const nextPageIndex = currentPageIndex + dir;
      const nextPage = pages[nextPageIndex];
      if (nextPage) navigate(`/${nextPage.slug}`, { replace: true });
    },
    [pages, currentPageIndex, navigate]
  );

  const handlers = useSwipeable({
    onSwipedLeft: handleSwap(1),
    onSwipedRight: handleSwap(-1),
  });

  return handlers;
};

export default useNavigation;
