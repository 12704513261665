import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
`;

export const Content = styled.div`
  margin-top: -1.5rem;
  z-index: 1;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 15px 15px 0px 0px;
  gap: 0.6rem;
`;
