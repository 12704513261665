import styled from "styled-components";
import ImgRes from "../Img";

export const Background = styled.div`
  background: rgba(0, 0, 0, 0.8);
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  color: #000;
`;

export const ModalContainer = styled.div`
  background: #fbfbfb;
  display: grid;
  grid-template-rows: auto 1fr auto;
  align-items: center;
  justify-items: center;
  width: 87.5%;
  height: 72.5%;

  @media (orientation: landscape) {
    height: 87.5%;
  }

  svg {
    fill: #000 !important;
    width: 5rem;
    height: 5rem;
  }
`;

export const Icon = styled(ImgRes)`
  width: 1.6rem;
  aspect-ratio: 1;
`;

export const Step = styled.div`
  border-radius: 50%;
  height: 7rem;
  width: 7rem;
  text-align: center;
  font-size: 4rem;
  line-height: 7rem;
  font-weight: 700;
  margin-top: 13vh;
`;

export const ModalContent = styled.div`
  text-align: center;
  width: clamp(200px, 60vw, 90vw);

  h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: 700;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: #8c8c8b;
    font-weight: 400;
    margin-top: 1.8rem;
  }
`;

export const NavOnboarding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(7.5rem + 8vh);
  margin-bottom: 8vh;
`;

export const Button = styled.button`
  border: none;
  background: #000;
  height: 4rem;
  width: 25rem;
  color: #fbfbfb;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 700;
`;

export const CloseOnboarding = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  font-size: 1.6rem;
  line-height: 2rem;
  height: 2rem;
  margin-top: 2rem;
`;
