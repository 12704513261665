import { useNavigate } from "react-router-dom";
import { usePages } from "../../context/PagesContext";
import { MarkersContainer, Marker } from "./MarkersElements";

const Markers = ({ slug }: MarkersProps) => {
  const { pages } = usePages();
  const navigate = useNavigate();

  return (
    <MarkersContainer>
      {pages.map((page, index) => (
        <Marker
          key={`marker-${index}`}
          active={page.slug === slug}
          onClick={() => navigate(`/${page.slug}`)}
        ></Marker>
      ))}
    </MarkersContainer>
  );
};

export default Markers;
