import { Container } from "./CategoriesElements";
import Category from "./Category";

const Categories = ({ categories }: CategoriesProps) => {
  return (
    <Container>
      {categories.playlist.map((playlist, index) => {
        return <Category key={`category-${index}`} playlist={playlist} />;
      })}
    </Container>
  );
};

export default Categories;
