/* eslint-disable no-restricted-globals */
import {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import NoContent from "../components/NoContent";
import Page from "../components/Page";
import useSessionStorage from "../hooks/useSessionStorage";

const PagesContext = createContext<ContentProps>({
  onboardingSteps: [],
  pages: [],
  currentPageIndex: 0,
} as ContentProps);

export const withPages = (WrappedComponent: ComponentType<AppProps>) => {
  const AppContextProvider = (
    props: Omit<ContentProps, "currentPageIndex">
  ) => {
    const location = useLocation();
    const [params] = useSearchParams();
    const [maxPage, setMaxPage] = useSessionStorage("maxPageIndex", 0);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);

    const buildPages = useCallback(() => {
      const appPages: AppPage[] = props.pages.map((page, index) => {
        return {
          pathname: `/${page.slug}`,
          content: page,
          element:
            index <= maxPage ? <Page page={page} /> : <NoContent page={page} />,
        };
      });
      appPages.push({
        pathname: `*`,
        content: appPages[0].content,
        element: <Navigate replace to={appPages[0].pathname} />,
      });
      return appPages;
    }, [props.pages, maxPage]);

    const [appPages, setPages] = useState(buildPages());

    useEffect(() => {
      if (location.pathname === "/") return;

      window.dataLayer.push({
        brand: "Renault",
        countryCode: "FR",
        googleAccount: "UA-5929204-2",
        languageCode: "fr",
        event: "virtualPageview",
        businessType: "my-renault",
        pageName: location.pathname,
        pageType: "mini-site",
      });

      setCurrentPageIndex(() => {
        const currentPageIndex = props.pages.findIndex(
          (page) => location.pathname === `/${page.slug}`
        );
        return currentPageIndex > -1 ? currentPageIndex : 0;
      });
    }, [location.pathname]);

    useEffect(() => {
      if (!params.get("status")) return;
      switch (params.get("status")) {
        case "PDC_OUT":
        case "READY_FOR_PICKUP":
        case "CUSTOMER_HANDOVER":
          setMaxPage(2);
          break;
        case "SHIPMENT":
          setMaxPage(1);
          break;
        case "FLEXIBILITY_END":
        case "MANUFACTURING":
        default:
          setMaxPage(0);
          break;
      }
    }, [params]);

    useEffect(() => {
      setPages(buildPages());
    }, [maxPage]);

    return (
      <PagesContext.Provider value={{ ...props, currentPageIndex }}>
        <WrappedComponent pages={appPages} />
      </PagesContext.Provider>
    );
  };

  return AppContextProvider;
};

export const usePages = () => useContext(PagesContext);
