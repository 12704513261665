import Hero from "./Hero";
import { Container, Content } from "./Page/PageElements";
import Markers from "./Markers";
import Timeline from "./Timeline";
import UnavailableContent from "./UnavailableContent";
import useNavigation from "../hooks/useNavigation";

const NoContent = ({ page }: PageProps) => {
  const handlers = useNavigation();
  return (
    <Container {...handlers}>
      <Hero content={page.hero} slug={page.slug} />
      <Content>
        <Markers slug={page.slug} />
        <Timeline />
        <UnavailableContent noContent={page.noContent} />
      </Content>
    </Container>
  );
};

export default NoContent;
