import { useParams } from "react-router-dom";
import { Container } from "./VideoElements";

const Video = () => {
  const { id } = useParams();

  return (
    <Container>
      <button onClick={() => history.back()}>&times;</button>
      <iframe
        src={`//www.youtube-nocookie.com/embed/${id}?fs=0&modestbranding=1&rel=0&showinfo=0&controls=0&autohide=1&disablekb=1`}
        frameBorder="0"
      />
    </Container>
  );
};

export default Video;
