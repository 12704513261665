import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Title = styled.h2`
  margin-left: 4rem;
  margin-right: 1rem;
  font-size: 2rem;
  line-height: 2.7rem;
  font-weight: 700;
`;

export const Playlist = styled.div`
  display: flex;
  gap: 1rem;
  overflow-x: scroll;
  width: 100%;
  padding-left: 4rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Video = styled(Link)`
  text-decoration: none;
  img {
    width: 50vw;
    height: auto;
    border-radius: 1rem;
  }
  span {
    color: #0f101a;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 700;
    display: block;
  }
`;
