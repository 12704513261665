import { Suspense } from "react";
import useNavigation from "../../hooks/useNavigation";
import Categories from "../Categories";
import Hero from "../Hero";
import Markers from "../Markers";
import Modal from "../Modal";
import { Spinner } from "../Spinner";
import Timeline from "../Timeline";
import { Container, Content } from "./PageElements";

const Page = ({ page }: PageProps) => {
  const handlers = useNavigation();
  return (
    <Suspense fallback={<Spinner />}>
      <Container {...handlers}>
        <Hero content={page.hero} slug={page.slug} />
        <Modal />
        <Content>
          <Markers slug={page.slug} />
          <Timeline />
          <Categories categories={page.content} />
        </Content>
      </Container>
    </Suspense>
  );
};

export default Page;
