import { usePages } from "../../context/PagesContext";
import useSessionStorage from "../../hooks/useSessionStorage";
import { ExperienceIcon, ConnectIcon, DiscoverIcon } from "../Icons";
import { Container, Step } from "./TimelineElements";

const Timeline = () => {
  const [activeStep] = useSessionStorage("maxPageIndex", 0);
  const { pages } = usePages();

  return (
    <Container>
      {pages.map((page, index) => {
        let Icon = null;
        switch (index) {
          case 1:
            Icon = ConnectIcon;
            break;
          case 2:
            Icon = ExperienceIcon;
            break;
          case 0:
          default:
            Icon = DiscoverIcon;
            break;
        }
        return (
          <Step
            key={`timeline-${index}`}
            $active={activeStep >= index}
            to={`/${page.slug}`}
          >
            <Icon />
          </Step>
        );
      })}
    </Container>
  );
};

export default Timeline;
