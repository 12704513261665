import styled from "styled-components";
import ImgRes from "../Img";

export const Container = styled.div<{ img: string }>`
  display: grid;
  place-items: center;
  position: relative;
  width: 100vw;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${({ img }) => img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 480px) {
    & {
      aspect-ratio: 375/400;
    }
  }

  /* Media Query for low resolution  Tablets, Ipads */
  @media (min-width: 481px) and (max-width: 767px) {
    & {
      height: 33.33vmax;
    }
  }

  /* Media Query for Tablets Ipads portrait mode */
  @media (min-width: 768px) and (max-width: 1024px) {
    & {
      height: 35vmax;
    }
  }

  /* Media Query for Laptops and Desktops */
  @media (min-width: 1025px) and (max-width: 1280px) {
    & {
      height: 25vmax;
    }
  }

  /* Media Query for Large screens */
  @media (min-width: 1281px) {
    & {
      height: 20vmax;
    }
  }
`;

export const Icon = styled(ImgRes)`
  width: 1.6rem;
  aspect-ratio: 1;
`;

export const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  color: #fff;
  padding: 3rem;
  font-weight: 700;

  h1 {
    font-size: 2.4rem;
    line-height: 3rem;
    text-align: center;
    padding: 0 1.7rem;
  }

  ${Icon} {
    width: 5rem;
  }
`;
