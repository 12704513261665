import styled from "styled-components";

export const MarkersContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-block: 1.5rem;
`;

export const Marker = styled.button<{ active?: boolean }>`
  width: ${({ active }) => (active ? "2.5rem" : "1.5rem")};
  height: ${({ active }) => (active ? "1.5rem" : "1rem")};
  background: ${({ active }) => (active ? "#8C8C8B" : "#BBBCBC")};
  border-radius: ${({ active }) => (active ? "0.75rem" : "0.5rem")};
  padding: 0;
  border: 0;
`;
