import { Suspense } from "react";
import { Route } from "react-router-dom";
import SlideRoutes from "react-slide-routes";
import { Spinner } from "./components/Spinner";
import Video from "./components/Video";
import { withPages } from "./context/PagesContext";
import { withData } from "./hooks/withData";

const App = ({ pages }: AppProps) => {
  const pathList = pages.map((p) => p.pathname);
  return (
    <Suspense fallback={<Spinner />}>
      <SlideRoutes duration={500} pathList={pathList}>
        {pages.map((page, index) => (
          <Route key={index} path={page.pathname} element={page.element} />
        ))}
        <Route path="/video/:id" element={<Video />} />
      </SlideRoutes>
    </Suspense>
  );
};

export default withData(withPages(App));
