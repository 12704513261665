import { ComponentType, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "../components/Spinner";
import useSessionStorage from "./useSessionStorage";

export const withData = (
  WrappedComponent: ComponentType<Omit<ContentProps, "currentPageIndex">>
) => {
  const AppDataFetcher = () => {
    const [params] = useSearchParams();
    const [language] = useSessionStorage("lang", params.get("lang") ?? "fr");
    const [data, setData] = useState<Omit<ContentProps, "currentPageIndex">>(
      {} as ContentProps
    );

    useEffect(() => {
      fetchData();
    }, [params]);

    const fetchData = async () => {
      try {
        const response = await fetch(`content/${language}.json`);
        if (response.ok) {
          const data = await response.json();
          setData(data);
        } else {
          throw new Error("Fetch request error");
        }
      } catch (err) {
        console.error(err);
        if (language !== "fr") fetchData();
      }
    };

    if (!Object.keys(data).length) return <Spinner />;

    return <WrappedComponent {...data} />;
  };

  return AppDataFetcher;
};
