import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  place-items: center;
  min-height: 100vh;
  background: linear-gradient(162.85deg, #3f4156 11.25%, #131524 92.51%);

  button {
    position: fixed;
    top: 2rem;
    right: 2rem;
    background: none;
    color: #fff;
    border: none;
    font-size: 3.5rem;
    padding: 1rem;
    line-height: 1;
  }

  iframe {
    aspect-ratio: 16/9;
    width: 100vw;
    height: auto;
  }

  @media (orientation: landscape) and (min-aspect-ratio: 16/9) {
    iframe {
      height: 100vh;
      width: auto;
    }
  }
`;
