import {
  Container,
  Title,
  Playlist,
  Video,
} from "../Categories/CategoryElements";
import Img from "../Img";

const Category = ({ playlist }: CategoryProps) => {
  const nbVideo = playlist.videos.length;
  return (
    <Container>
      <Title>
        {playlist.title}&nbsp;({nbVideo})
      </Title>
      <Playlist className="avoid-swipping">
        {playlist.videos.map((video, index) => (
          <Video
            key={`video-${playlist.title}-${index}`}
            to={`/video/${video.youtubeId}`}
          >
            <Img src={video.thumbnail.src} alt={video.thumbnail.alt} />
            <span>{video.title}</span>
          </Video>
        ))}
      </Playlist>
    </Container>
  );
};

export default Category;
