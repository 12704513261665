import { useCallback, useState } from "react";
import { usePages } from "../../context/PagesContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import { MediaIcon, TimerIcon, WelcomeIcon } from "../Icons";
import { MarkersContainer, Marker } from "../Markers/MarkersElements";
import {
  Button,
  CloseOnboarding,
  Background,
  ModalContent,
  NavOnboarding,
  Step,
  ModalContainer,
} from "./ModalElements";
import ReactPortal from "./ReactPortal";

const Modal = () => {
  const { onboardingSteps } = usePages();
  const [step, setStep] = useState(0);
  const [showModal, setShowModal] = useLocalStorage("showModal", true);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const GoToStep = useCallback(
    (step: number) => {
      if (step === onboardingSteps.length) {
        closeModal();
        return;
      }
      setStep(step);
    },
    [step, onboardingSteps, closeModal]
  );

  if (!showModal) return null;

  let Icon = null;
  switch (onboardingSteps[step].step) {
    case 2:
      Icon = TimerIcon;
      break;
    case 3:
      Icon = MediaIcon;
      break;
    case 1:
    default:
      Icon = WelcomeIcon;
      break;
  }

  return (
    <ReactPortal>
      <Background>
        <ModalContainer>
          <Step>
            <Icon />
          </Step>

          <ModalContent>
            <h1>{onboardingSteps[step].title}</h1>
            <p>{onboardingSteps[step].content}</p>
          </ModalContent>

          <NavOnboarding>
            <MarkersContainer>
              {onboardingSteps.map((_, index) => {
                return (
                  <Marker
                    key={`marker-${index}`}
                    active={index === step}
                  ></Marker>
                );
              })}
            </MarkersContainer>

            <Button onClick={() => GoToStep(step + 1)}>
              {onboardingSteps[step].button}
            </Button>

            {step < onboardingSteps.length - 1 && (
              <CloseOnboarding onClick={() => closeModal()}>
                passer
              </CloseOnboarding>
            )}
          </NavOnboarding>
        </ModalContainer>
      </Background>
    </ReactPortal>
  );
};

export default Modal;
