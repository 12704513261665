import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  gap: 3rem;

  svg {
    fill: #000 !important;
    width: 6rem;
    height: 6rem;
  }
`;

export const ChapterInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: 7rem;

  h2 {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8rem;
    text-align: center;
  }
`;
